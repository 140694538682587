import { jsPDF } from 'jspdf';
import { font, bold } from '../fonts';
import { Study, Subsidiary } from '../../../types';
import { addToPdf, ITEMS_PER_PAGE } from './AddToPDF';

export const generatePdf = (items: Study[], subsidiary: Subsidiary) => {
  const document = new jsPDF({ format: 'letter', unit: 'px' });

  document.addFileToVFS('Sk-Modernist-Regular.ttf', font);
  document.addFont('Sk-Modernist-Regular.ttf', 'Modernist', 'normal');
  document.setFont('Modernist');

  document.addFileToVFS('Sk-Modernist-Bold.ttf', bold);
  document.addFont('Sk-Modernist-Bold.ttf', 'Modernist', 'bold');
  document.setFont('Modernist');

  addToPdf({
    document, 
    page: 0, 
    pages: Math.ceil(items.length / ITEMS_PER_PAGE),
    items,
    imageUrl: '/banner-cot.png',
    mapUrl: 'https://maps.app.goo.gl/u3Muv22epDcX1gtE7',
    mapImage: subsidiary.mapsUrl,
    mapAddress: subsidiary.address + subsidiary.colony
  });
};
