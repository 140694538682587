import { FC } from "react";
import { Study } from "../../../../types";
import { Box } from "@mui/material";
import BudgetItem from "../../../common/BudgetItem/BudgetItem";
import { useDispatch } from "react-redux";
import { removeFromBudget } from "../../../../store/reducers/budget";

interface StudyListProps {
  list: Study[];
}

const StudyList: FC<StudyListProps> = ({ list }) => {
  const elementStyle = {
    marginBottom: '24px'
  };

  const lastElementStyle = {
    marginBottom: 0
  };

  const dispatch = useDispatch();
  const onDelete = (study: Study) => {
    dispatch(removeFromBudget(study));
  };

  return (
    <>
      {list.map((study, index) => (
        <Box sx={index === list.length - 1 ? lastElementStyle : elementStyle} key={study.sku}>
          <BudgetItem study={study} onDelete={() => onDelete(study)} />
        </Box>
      ))}
    </>
  );
}

export default StudyList;
