import { FC } from "react";
import { Button, ButtonProps } from "@mui/material";
import { Study } from "../../../types";
import { useDispatch, useSelector } from "react-redux";
import { budgetSelector } from "../../../store/selectors";
import { addToBudget, removeFromBudget } from "../../../store/reducers/budget";

interface BudgetizeButtonProps extends ButtonProps {
  study: Partial<Study>;
  onClick?: () => void;
}

const BudgetizeButton: FC<BudgetizeButtonProps> = ({ study, onClick, ...props }) => {
  const dispatch = useDispatch();
  const budget = useSelector(budgetSelector);
  const alreadyInBudget = budget.findIndex(s => s.sku === study.sku) > -1;

  const handleClick = () => {
    if (alreadyInBudget) {
      dispatch(removeFromBudget(study as Study));
    } else {
      dispatch(addToBudget(study as Study));
    }

    onClick?.();
  }

  return (
    <Button
      fullWidth={props.fullWidth}
      variant="contained"
      color={alreadyInBudget ? "inherit" : "light"}
      onClick={handleClick}
    >
      {alreadyInBudget ? 'Eliminar' : 'Cotizar'}
    </Button>
  );
}

export default BudgetizeButton;
