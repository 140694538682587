export const MAX_WIDTH = 1480;

export const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;

export const SUBSIDIARY_STORAGE_NAME = 'SUB';

export const BUDGET_STORAGE_NAME = 'BUDGET';

export const WHATSAPP_URL = 'https://wa.me';

export const PHONE_NUMBER = '+525633575330';
