import { Box, Button, styled } from "@mui/material";
import { FC } from "react";

interface BudgetizerCTAsProps {
  onAdd?: () => void;
  onContinue?: () => void;
}

const BudgetizerBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  columnGap: theme.spacing(8),
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    rowGap: theme.spacing(3),
    flexDirection: 'column',
  }
}));

const BudgetizerCTAs: FC<BudgetizerCTAsProps> = ({ onAdd, onContinue }) => {
  return (
    <BudgetizerBox>
      <Button
        sx={{ flex: '1 1 0px', minWidth: '250px' }}
        variant="contained"
        color="whiteBlue"
        onClick={() => onAdd?.()}
      >
        Agregar otro estudio
      </Button>
      <Button
        sx={{ flex: '1 1 0px', minWidth: '250px' }}
        variant="contained"
        color="light"
        onClick={() => onContinue?.()}
      >
        Generar cotización
      </Button>
    </BudgetizerBox>
  );
};

export default BudgetizerCTAs;
