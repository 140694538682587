import { FC } from "react";
import { Footer, Header, Page } from "./Components";

interface MapPageProps {
  bannerUrl: string;
  mapUrl: string;
  mapImage: string,
  address: string;
}

export const MapPage: FC<MapPageProps> = ({ bannerUrl, mapImage, mapUrl, address }) => (
  <>
    <Page>
      <>
        <Header bannerUrl={bannerUrl} />
        <div>
          <h2 style={{ fontFamily: 'Modernist', fontWeight: 'bold', textAlign: 'center' }}>¿Cómo llegar?</h2>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            rowGap: '50px',
            height: '300px',
            width: '450px'
          }}>
            <div style={{ margin: 18, padding: 2, borderRadius: 10, border: '1px solid grey', height: 200 }}>
              <a href={mapUrl}><img src={`${mapImage}?noCors=true`} alt="mapa" width={200} height={200} crossOrigin="anonymous" /></a>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', columnGap: 20, fontFamily: 'Modernist', fontWeight: 'normal' }}>
              <p><b>Dirección:</b></p>
              <p>{address}</p>
              
            </div>
            
          </div>
          <a href={mapUrl} style={{ wordWrap: 'break-word', textAlign: 'center', fontFamily: 'Modernist', marginLeft: 20 }}>{mapUrl}</a>
        </div>
      </>
    </Page>
    <Footer />
  </>
);
