import { FC, useCallback } from "react";
import StudyList from "./components/StudyList/StudyList";
import { Study, Subsidiary } from "../../types";
import TotalText from "./components/TotalText.tsx/TotalText";
import { CenteredBox } from "../common/CenteredBox/CenteredBox";
import BudgetizerCTAs from "./components/BudgetizerCTAs/BudgetizerCTAs";
import { Button, Link, Typography } from "@mui/material";
import { Paths } from "../../routes/Paths";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { budgetSelector, subsidiarySelector } from "../../store/selectors";
import Modal from "../common/Modal/Modal";
import { generatePdf } from "../common/PDFGenerator/GeneratePDF";


const Budgetizer: FC<{}> = () => {
  const navigate = useNavigate();

  const subsidiary = useSelector(subsidiarySelector) as Subsidiary;
  const list: Study[] = useSelector(budgetSelector);
  const total: number = list
    .reduce((total, study) => total + Number(study.price.value), 0);

  // If no items, render empty page
  // Add a link to go/return to catalog
  const getEmptyPage = useCallback(() => (
    <CenteredBox data-testid="empty-container" sx={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '50%',
      justifyContent: 'center',
    }}>
      <Typography variant="h1" color="primary" fontWeight="light" padding="32px">Tu cotización está vacía</Typography>
      <Typography variant="h5" fontWeight="normal">Puedes agregar estudios a tu cotización</Typography>
      <Typography><Link href={Paths.CATALOG}>Regresar al catálogo</Link></Typography>
    </CenteredBox>
  ), []);

  const returnToCatalog = () => {
    navigate(Paths.CATALOG);
  }
  
  const getBudgetPage = useCallback(() => (
    <>
      <CenteredBox sx={{ marginBottom: 3 }}>
        <Typography color="primary" variant="h1">
          Cotización
        </Typography>
      </CenteredBox>

      <StudyList list={list} />
      <CenteredBox sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '32px'
      }}>
        <TotalText total={total} />
        <BudgetizerCTAs onAdd={returnToCatalog} onContinue={() => generatePdf(list, subsidiary)} />
      </CenteredBox>
      <Modal>
        <>
          <Typography>Seguro?</Typography>
          <Button>Sí</Button>
          <Button>No</Button>
        </>
      </Modal>
    </>
  ), [list, total]);

  const renderPage = () => list.length > 0
    ? getBudgetPage()
    : getEmptyPage();

  return renderPage();
}

export default Budgetizer;
