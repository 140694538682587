import { FC } from "react";
import { Study } from "../../../types";
import { Box, Footer, Header, Page } from "./Components";

type PdfDocumentProps = {
  bannerUrl: string;
  mapUrl: string;
  budget: Study[];
  total: number;
  displayTotal: boolean;
}


export const PdfDocument: FC<PdfDocumentProps> = ({ bannerUrl, mapUrl, budget, total, displayTotal }) => {
  return (
    <>
      <Page>
        <>
          <Header bannerUrl={bannerUrl} />
          <div style={{ padding: '20px', fontSize: '10px', fontFamily: 'Modernist', fontWeight: 'normal', width: '400px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontWeight: 'bold' }}>
              <h4><b>Análisis</b></h4>
              <h4>Indicaciones</h4>
              <h4>Precio</h4>
            </div>

            {budget.map(s => (
              <div style={{ display: 'flex', flexDirection: 'row', rowGap: '40px', justifyContent: 'space-between' }} key={s.sku}>
                <img src='icons/resp.png' alt={s.name} width={35} height={35} />
                <div style={{ width: '80px', textAlign: 'center', fontFamily: 'Modernist', fontWeight: 'normal', fontSize: '10px' }}>
                  <Box>
                    <p color='primary'>{s.name}</p>
                  </Box>
                </div>
                <div style={{ width: '230px', fontSize: '8px' }}>
                  <Box justifyContent="left" textAlign="left">
                    <p color='primary'>{s.indications}</p>
                  </Box>
                </div>
                <div style={{ color: '#4E71B1', fontSize: '15px', fontWeight: 'bold', textAlign: 'right', verticalAlign: 'middle' }}>
                  <Box justifyContent="left" textAlign="right" padding="0 0 0 16">
                    <p color='primary'>${s.price.value}</p>
                  </Box>
                </div>
              </div>
            ))}

            {displayTotal && (
              <div style={{ textAlign: 'right' }}>
                <p style={{ fontSize: '15px' }}><b>Total </b>&nbsp;&nbsp;&nbsp; <b style={{ color: '#4E71B1' }}>${total}</b></p>
              </div>
            )}
          </div>
        </>
      </Page>
      <Footer />
    </>
  );
}