import { configureStore } from '@reduxjs/toolkit';
import subsidiariesReducer from './reducers/subsidiary';
import budgetSlice from './reducers/budget';

export const store = configureStore({
  reducer: {
    subsidiaries: subsidiariesReducer,
    budget: budgetSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch
