import jsPDF from "jspdf";
import { Study } from "../../../types";
import { renderToString } from "react-dom/server";
import { ThemeProvider } from "@mui/material";
import { PdfDocument } from "./PDFDocument";
import { BPTheme } from "../../../theme/Theme";
import { MapPage } from "./Map";

export const ITEMS_PER_PAGE = 5;

type PDFParams = {
  document: jsPDF,
  page: number,
  pages: number,
  items: Study[],
  imageUrl: string,
  mapUrl: string,
  mapImage: string,
  mapAddress: string,
};

export function addToPdf({ document, page, pages, items, imageUrl, mapImage, mapUrl, mapAddress }: PDFParams) {
  if (page >= pages) {
    const pageCount = document.getNumberOfPages();
    const pageHeight = document.internal.pageSize.getHeight();

    const mapPage = renderToString(
      <ThemeProvider theme={BPTheme}>
        <MapPage bannerUrl={imageUrl} mapImage={mapImage} mapUrl={mapUrl} address={mapAddress}/>
      </ThemeProvider>
    );

    document.deletePage(pageCount);
    document.addPage('letter', 'portrait');

    document.html(mapPage, {
      callback: function (instance: jsPDF) {
        const newPageCount = document.getNumberOfPages();
        instance.deletePage(newPageCount);
        instance.save('cotizacion_bplab.pdf');
        return;
      },
      x: 0,
      y: page * pageHeight,
    });

    return;
  }

  const pageHeight = document.internal.pageSize.getHeight();
  const itemsForCurrentPage = items.slice(page * ITEMS_PER_PAGE, (page * ITEMS_PER_PAGE) + ITEMS_PER_PAGE);
  const remainingItems = items.slice((page + 1) * ITEMS_PER_PAGE, (page + 1 * ITEMS_PER_PAGE) + ITEMS_PER_PAGE);
  const shouldDisplayTotal = remainingItems.length === 0;
  const total = items.reduce((acc, study) => acc + Number(study.price.value), 0);

  const string = renderToString(
    <ThemeProvider theme={BPTheme}>
      <PdfDocument 
        bannerUrl={imageUrl}
        mapUrl=''
        budget={itemsForCurrentPage}
        total={total}
        displayTotal={shouldDisplayTotal}
      />
    </ThemeProvider>
  );

  document.html(string, {
    callback: function (instance: jsPDF) {
      instance.addPage('letter', 'portrait');
      addToPdf({
        document: instance,
        page: page + 1,
        pages,
        items,
        imageUrl,
        mapImage,
        mapUrl,
        mapAddress,
      });
    },
    x: 0,
    y: page * pageHeight,
  });
}