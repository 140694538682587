import { FC } from "react";


interface PageProps {
  children: string | JSX.Element | JSX.Element[];
}


export const Page: FC<PageProps> = ({ children }) => {
  return (
    <div style={{ padding: '0', width: '460px', minHeight: '514px' }} id="page">
      {children}
    </div>
  );
};

interface HeaderProps {
  bannerUrl: string;
}

export const Header: FC<HeaderProps> = ({ bannerUrl }) => {
  return (
    <img src={bannerUrl} alt="header" style={{ width: '100%', height: '100%' }} />
  );
}

export const Box: FC<any> = (props) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: props.justifyContent ?? 'center',
      padding: props.padding ?? `0 16px`,
      alignItems: 'center',
      marginBottom: 0,
      textAlign: props.textAlign ?? 'center',
      textOverflow: 'ellipsis',
    }}>
    {props.children}
  </div>
);

export const Footer: FC<{}> = () => (
  <div id="footer" style={{ background: '#094FA4', height: '80px', position: 'relative', bottom: '0', left: '0', width: '480px' }} />
);