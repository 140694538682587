import { FC } from "react";
import { Study } from "../../../types";
import { Box, styled } from "@mui/material";
import { DescriptiveSection } from "./DescriptiveSection";

interface BudgetItemProps {
  study: Study;
  onDelete?: () => void;
  useLinks?: boolean;
}

const ItemBox = styled(Box)(({ theme }) => ({
  boxShadow: '2px 2px 14px 0px rgba(0,0,0,.1)',
  display: 'flex',
  flexDirection: 'row',
  padding: `${theme.spacing(3)} ${theme.spacing(6)}`,
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    flexDirection: 'column',
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  }
}));

// TODO: Separate into files

interface DeleteIconProps {

}

const DeleteIcon: FC<DeleteIconProps & React.ImgHTMLAttributes<{}>> = ({ ...props }) => {
  const DeleteImg = styled('img')(({ theme }) => ({
    cursor: 'pointer',
    objectFit: 'contain',
    ...props.style,
  }));
  const { style, ...onlyProps } = props;

  return (
    <DeleteImg src="icons/delete-one.png" {...onlyProps} />
  );
}

const ItemIcon: FC<React.ImgHTMLAttributes<{}>> = ({ ...props }) => {
  const ItemImg = styled('img')(({ theme }) => ({
    objectFit: 'contain',
    width: `${theme.spacing(11)}`,
    ...props.style,
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2)
    }
  }));

  return (
    <ItemImg src="icons/resp.png" />
  );
}

// END TODO

const BudgetItem: FC<BudgetItemProps> = ({ study, onDelete, useLinks = true }) => {


  return (
    <ItemBox>
      <Box sx={{ alignItems: 'center', display: { lg: 'none', md: 'none', sm: 'none', xs: 'flex' }, marginLeft: 'auto' }}>
        <DeleteIcon onClick={onDelete} />
      </Box>
      <ItemIcon src='icons/resp.png' alt={study.name} />
      <DescriptiveSection
        header={study.category}
        value={study.name}
        sku={study.sku}
        useLinks={useLinks}
      />
      <DescriptiveSection
        header="Sucursal"
        value={study.price.subsidiary}
        sku={study.sku}
        useLinks={useLinks}
      />
      <DescriptiveSection
        header="Precio"
        value={`$${study.price.value}`}
        sku={study.sku}
        useLinks={useLinks}
      />
      <Box sx={{ alignItems: 'center', display: { lg: 'flex', md: 'flex', sm: 'flex', xs: 'none' }, marginLeft: 'auto' }}>
        <DeleteIcon onClick={() => { console.log('click'); onDelete?.(); }} />
      </Box>
    </ItemBox>
  );
};

export default BudgetItem;
