import { Typography } from "@mui/material";
import { FC } from "react";

interface TotalTextProps {
  total: number;
}

const TotalText: FC<TotalTextProps> = ({ total }) => {
  return <Typography fontSize={24} fontWeight='light' color="primary">Total: <b>${total}</b></Typography>;
}

export default TotalText;
