import { Box, InputAdornment, TextField, styled, /* useMediaQuery, useTheme */ } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { CenteredBox } from "../common/CenteredBox/CenteredBox";
import { BlueDivisor } from "../common/BlueDivisor/BlueDivisor";
import Results from "./components/Results/Results";
// import HorizontalLinks from "../common/HorizontalLinks/HorizontalLinks";
import { useSearchParams } from "react-router-dom";
// import MobileLinks from "../common/HorizontalLinks/MobileLinks";

export interface CatalogProps {

}

const FlexBox = styled(CenteredBox)(({ theme }) => ({
  flexFlow: 'row wrap',
  justifyContent: 'flex-start',
  columnGap: theme.spacing(6),
  '& *': {
  }
}));

const Catalog: FC<CatalogProps> = () => {
  // const theme = useTheme();
  // const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams] = useSearchParams();
  const Divisor = BlueDivisor('#f6f6f6');

  // const links = [{
  //   text: 'Todo',
  //   value: ''
  // }, {
  //   text: 'Para ellas',
  //   value: 'ellas'
  // }, {
  //   text: 'Para ellos',
  //   value: 'ellos'
  // }, {
  //   text: 'Las más populares',
  //   value: 'populares'
  // }];

  const [tag, setTag] = useState('todo');
  // const onSelectValue = (value: string) => {
  //   setSelected(value);
  //   setTag(value);
  // }

  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    const searchFromLP = searchParams.get('search');

    if (searchFromLP) {
      setSearch(searchFromLP);
      setSearchText(searchFromLP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FlexBox sx={{
        display: 'flex',
        margin: '24px 0',
        flex: { lg: 0.5, md: 1, sm: 1 },
        flexDirection: {
          // lg: 'row',
          // md: 'row',
          sm: 'column',
          xs: 'column',
        },
        textAlign: 'center',
        justifyContent: 'space-between',
        rowGap: 1,
      }}>
        {/* {!isTablet ?
          (<>
            <HorizontalLinks links={links} selected={selected} onSelectValue={onSelectValue} />
            <Divisor sx={{ display: { lg: 'none', md: 'none', sm: 'block', xs: 'block' }, position: 'relative', top: '-26px', zIndex: -1 }} />
          </>) :
          <MobileLinks selected={selected} links={links} onSelectValue={onSelectValue} />
        } */}

        <Box sx={{ flex: { lg: 0.7, md: 0.9 } }}>
          <TextField
            sx={{
              borderRadius: 4,
              border: '5px solid #009EE9',
              position: {
                md: 'relative',
                sm: 'relative',
              },
              top: {
                md: '-12px',
                sm: '0'
              },
              flex: 1,
              flexBasis: {
                lg: '50%',
                md: '100%',
                sm: '100%'
              },
            }}
            fullWidth
            placeholder="Da click en la lupa para buscar"
            size="small"
            type="text"
            variant="outlined"
            id="search-study"
            onChange={e => setSearch(e.target.value)}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                setSearchText(search)
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img onClick={() => setSearchText(search)} src="icons/search.png" alt="Buscar" style={{ height: '24px', padding: 6, cursor: 'pointer' }} />
                </InputAdornment>
              )
            }}
          />
        </Box>
      </FlexBox >
      <Divisor sx={{ display: { lg: 'block', md: 'block', sm: 'none', xs: 'none' }, position: 'relative', top: '-26px', zIndex: -1 }} />

      <Results tag={tag} search={searchText} />
    </>

  );
};

export default Catalog;
