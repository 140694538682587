import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Study } from "../../types";
import { BUDGET_STORAGE_NAME } from "../../constants";

interface BudgetState {
  items: Study[];
}

function getBudgetFromStorage() {
  return JSON.parse(sessionStorage.getItem(BUDGET_STORAGE_NAME) || 'null') as Study[];
}

function setBudgetInStorage({ budget, study }: { budget?: Study[], study?: Study }) {
  if (study) {
    sessionStorage.setItem(BUDGET_STORAGE_NAME, JSON.stringify([study]));
  }

  if (budget) {
    sessionStorage.setItem(BUDGET_STORAGE_NAME, JSON.stringify(budget));
  }
}


const initialState: BudgetState = {
  items: getBudgetFromStorage() ?? [],
};

const isStudyNotInBudget = (state: BudgetState, study: Study) =>
  state.items.findIndex(item => item.sku === study.sku) === -1;

export const budgetSlice = createSlice({
  name: 'budget',
  initialState,
  reducers: {
    clearBuget: (state) => {
      state.items = [];
      setBudgetInStorage({ budget: [] });
    },
    addToBudget: (state, action: PayloadAction<Study>) => {
      if (isStudyNotInBudget(state, action.payload)) {
        state.items.push(action.payload);
        setBudgetInStorage({ budget: state.items });
      }
    },
    removeFromBudget: (state, action: PayloadAction<Study>) => {
      const indexToRemove = state.items.findIndex(s => s.sku === action.payload.sku);

      if (indexToRemove > -1) {
        state.items.splice(indexToRemove, 1);
        setBudgetInStorage({ budget: state.items });
      }
    },
  }
});

export const { addToBudget, clearBuget, removeFromBudget } = budgetSlice.actions;

export default budgetSlice.reducer;