import { FC } from "react";
import { Box, Typography, styled } from "@mui/material";
import { Link } from "react-router-dom";
import { Paths } from "../../../routes/Paths";

const DescriptionBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(6)}`,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      marginBottom: theme.spacing(2),
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      width: '30%'
    },
    [theme.breakpoints.only('sm')]: {
      width: '50%'
    },
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& p': {
      overflow: 'hidden'
    }
  }));

interface DescriptiveSectionProps {
    header: string;
    value: string;
    sku: string;
    useLinks?: boolean
}

export const DescriptiveSection: FC<DescriptiveSectionProps> = ({ header, value, sku, useLinks = true }) => (
    <DescriptionBox>
      {useLinks ? (<Link style={{ textDecoration: 'none' }} to={`${Paths.DETAILS.replace(':sku', sku)}`}>
        <Typography variant="caption">{header}</Typography>
        <Typography padding={0} color='primary' fontWeight='light' textOverflow="ellipsis">{value}</Typography>
      </Link>)
      : (
        <>
          <Typography variant="caption">{header}</Typography>
          <Typography padding={0} color='primary' fontWeight='light' textOverflow="ellipsis">{value}</Typography>
        </>
      )}
    </DescriptionBox>
  );